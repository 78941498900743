import { Injectable } from '@angular/core';
import { IError } from '../models';
import { ProjectID } from '../project-id.enum';
import { environment } from './../../../environments/environment';
import { DataService } from './../communication/rest-service-invoker.service';
import { CredentialsService } from './../credentials.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private user: CredentialsService,
    private dataService: DataService
  ) {}

  addError(param: IError): void {
    try {
      param.projectID = ProjectID.MusteriPortal;
        param.deviceType = 3;
        param.deviceToken = "";
        param.appID = environment.appID;
        param.appVersion = "";
        param.exception = JSON.stringify(param.exception);
        param.api = environment.serverUrl;
        param.kullaniciID = this.user.credentials?.kullaniciID;
        param.parameters = JSON.stringify(this.user.credentials);
        this.dataService
          .post('authenticate/addError', {
            Page: param.page,
            Method: param.method,
            Exception: param.exception,
            ProjectID: param.projectID,
            DeviceType: param.deviceType,
            DeviceToken: param.deviceToken,
            AppID: param.appID,
            AppVersion: param.appVersion,
            KullaniciID: param.kullaniciID,
            Parameters: param.parameters,
            Api: param.api,
          })
          .subscribe(
            (x) => {
            },
            (error) => {
            }
          );
    }
    catch{
    }
  }
}
