import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelerikReportViewerComponent } from './telerik-report-viewer.component';
/**
 * Represents the [NgModule](https://angular.io/docs/ts/latest/guide/ngmodule.html)
 * definition for the Telerik Report Viewer component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Grid module
 * import { TelerikReportingModule } from './telerik-reporting/telerik-reporting.module';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * @@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, TelerikReportingModule], // import Grid module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * ```
 */
var TelerikReportingModule = /** @class */ (function () {
    function TelerikReportingModule() {
    }
    TelerikReportingModule.decorators = [
        { type: NgModule, args: [{
                    imports: [CommonModule],
                    exports: [TelerikReportViewerComponent],
                    declarations: [TelerikReportViewerComponent],
                },] },
    ];
    /** @nocollapse */
    TelerikReportingModule.ctorParameters = function () { return []; };
    return TelerikReportingModule;
}());
export { TelerikReportingModule };
